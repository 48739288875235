// import { olografos } from "olografos";

const _checkFormat = (word) => {
  if (word.length === 0) throw new Error("Greek names cannot be null.");
  if (/[^α-ωΑ-Ωά-ώΆ-Ώ]/gi.test(word))
    throw new Error("Greek names should only include greek letters.");
};

export const checkAFM = (afm) => {
  if (afm.length !== 9) throw new Error("AFM should be 9 digits long.");
  if (/[^0-9]/gi.test(afm)) throw new Error("AFM should only include digits.");
};

const _removeDiacritics = (text) =>
  text.normalize("NFD").replace(/\p{Diacritic}/gu, "");

const _formatWord = (word) => _removeDiacritics(word.trim()).toLowerCase();

// a function remove from word every of the following: dashes, slashes, dots

const removeDashes = (word) => {
  const arr = word.split("-");
  return arr.join("");
};

export const removeDiacriticsAndCapitalize = (text) =>
  _removeDiacritics(text.trim()).toUpperCase();

export const checkIfFemale = (word) => {
  // remove from word every of the following: dashes, slashes, dots

  word = removeDashes(word);

  try {
    _checkFormat(word);
  } catch (error) {
    return false;
  }
  const formatted = _formatWord(word).split(" ")[0].split("-")[0];
  if (formatted[formatted.length - 1] === "α") return true;
  if (formatted[formatted.length - 1] === "η") return true;
  if (formatted[formatted.length - 1] === "ω") return true;
  return false;
};

export const checkIfPlural = (arr) => {
  if (arr.length > 1) return true;
  return false;
};

// function to remove duplicates from an array
export const removeDuplicates = (arr) => {
  const unique = [...new Set(arr)];
  return unique;
};

// function that takes a date object and returns a string in the format yyyy-mm-dd, and if the date is null returns null. Add 0 to month and day if they are less than 10
export const formatKeepOnlyDate = (date) => {
  if (date === null) return null;
  let month = date.getMonth() + 1;
  if (month < 10) month = "0" + month;
  let day = date.getDate();
  if (day < 10) day = "0" + day;
  return date.getFullYear() + "-" + month + "-" + day;
};

// it takes a date in the format of dd-mm-yyyy or dd/mm/yyyy or dd.mm.yyyy and returns a string in the format yyyy-mm-dd
export function dateToYYYYMMDD(dateString) {
  if (dateString === null) return null;
  if (dateString.includes("-")) {
    const date = dateString.split("-");
    return date[2] + "-" + date[1] + "-" + date[0];
  }
  if (dateString.includes("/")) {
    const date = dateString.split("/");
    return date[2] + "-" + date[1] + "-" + date[0];
  }
  if (dateString.includes(".")) {
    const date = dateString.split(".");
    return date[2] + "-" + date[1] + "-" + date[0];
  }
}

export function normalizeAuctionDates(dt) {
  dt = JSON.parse(dt);
  let imerominiesAnartisis = dt
    .map((date, i) => {
      if (i % 2 === 0) {
        return date;
      } else {
        return undefined;
      }
    })
    .filter((date) => date);
  imerominiesAnartisis = [...new Set(imerominiesAnartisis)];
  const arr = [];
  imerominiesAnartisis.forEach((date) => {
    const imerominiesDiexagogis = dt.filter((d, i) => {
      if (i % 2 !== 0 && dt[i - 1] === date) {
        return d;
      }
    });
    const obj = {
      imerominia_anartisis: date,
      imerominia_diexagogis: `${imerominiesDiexagogis[0]} (${imerominiesDiexagogis.length})`,
    };
    arr.push(obj);
  });

  return arr;
}
export function mergeDates(inputString) {
  const dateCounts = {}; // To store the counts for each date
  const output = [];
  console.log(inputString);

  // Split the input string by commas and trim any spaces
  if (typeof inputString !== "string") return inputString;
  if (!inputString?.includes(",")) return inputString;
  const entries = inputString?.split(",").map((entry) => entry.trim());

  // Loop through each entry
  for (const entry of entries) {
    // Extract the date and count using regular expression
    const match = entry.match(/^(\d{2}\/\d{2}\/\d{4})\s\((\d+)\)$/);
    if (match) {
      const date = match[1];
      const count = parseInt(match[2], 10);

      // Add the count to the date in the object
      if (dateCounts[date]) {
        dateCounts[date] += count;
      } else {
        dateCounts[date] = count;
      }
    }
  }

  // Convert the object back to a string format
  for (const [date, count] of Object.entries(dateCounts)) {
    output.push(`${date} (${count})`);
  }

  return output.join(", ");
}

// get previous day in the form of a string in the format dd/mm/yyyy
export function getPreviousDay() {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  return dateToDDMMYYYY(formatKeepOnlyDate(yesterday));
}

// it takes a date in the format of yyyy-mm-dd or yyyy/mm/dd and returns a string in the format dd/mm/yyyy
export function dateToDDMMYYYY(dateString) {
  if (dateString === null) return null;
  if (dateString.includes("-")) {
    const date = dateString.split("-");
    return date[2] + "/" + date[1] + "/" + date[0];
  }
  if (dateString.includes("/")) {
    const date = dateString.split("/");
    return date[2] + "/" + date[1] + "/" + date[0];
  }
}

// export function transformEggyisi(num) {
//   if (num === 1) {
//     return "ένα (1) μηνιαίο μίσθωμα";
//   }
//   if (num > 1) {
//     return `${olografos(parseInt(num))} (${num}) μηνιαία μισθώματα`;
//   }
// }

export const acceptedABMChars = [
  "Α",
  "Β",
  "Γ",
  "Δ",
  "Ε",
  "Ζ",
  "Η",
  "Θ",
  "Ι",
  "Κ",
  "Λ",
  "Μ",
  "Ν",
  "Ξ",
  "Ο",
  "Π",
  "Ρ",
  "Σ",
  "Τ",
  "Υ",
  "Φ",
  "Χ",
  "Ψ",
  "Ω",
  "-",
  "/",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
];

export function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
