import ReactModal from "react-modal";

export const DeleteUserModal = ({
  isOpen,
  onRequestClose,
  handleDeleteUser,
}) => {
  const handleClickDeleteUser = () => {
    handleDeleteUser();
    onRequestClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          height: "fit-content",
          margin: "0 auto",
          width: "fit-content",
          zIndex: "1000",
          fontFamily: "Manrope, sans-serif",
        },
        overlay: {
          zIndex: "999",
        },
      }}
    >
      <h2>Διαγραφή Χρήστη</h2>
      <p>Είστε σίγουρος ότι θέλετε να διαγράψετε τον χρήστη;</p>
      <div className="delete-user-btns">
        <button
          onClick={handleClickDeleteUser}
          className="addcase_modal check_now post-footer-delete"
        >
          Διαγραφή
        </button>
      </div>
    </ReactModal>
  );
};

export const DeletePostModal = ({
  isOpen,
  onRequestClose,
  handleDeletePost,
}) => {
  const handleClickDeletePost = () => {
    handleDeletePost();
    onRequestClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          height: "fit-content",
          margin: "0 auto",
          width: "fit-content",
          zIndex: "1000",
          fontFamily: "Manrope, sans-serif",
        },
        overlay: {
          zIndex: "999",
        },
      }}
    >
      <h2>Είστε σίγουρος ότι θέλετε να διαγράψετε την ανάρτηση;</h2>
      <div className="delete-user-btns">
        <button
          onClick={handleClickDeletePost}
          className="addcase_modal check_now post-footer-delete"
        >
          Διαγραφή
        </button>
      </div>
    </ReactModal>
  );
};
