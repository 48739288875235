import { useState, useEffect, useContext } from "react";
import Cases from "./Cases";
import AddCase from "./AddCase";
import AddCaseModal from "./AddCaseModal";
import Imerologio from "./Imerologio";
import { useInterval } from "../utils/useInterval";
import AuthContext from "../context/AuthProvider";

import DashboardTop from "./Updates/DashboardTop";
import axios from "axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { Card, Text } from "@tremor/react";
import RotatingElements from "./rotating";

const Admin = () => {
  const [cases, setCases] = useState([]);
  const [prothesmies, setProthesmies] = useState([]);
  const [casesFirst, setCasesFirst] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [notif, setNotif] = useState(true);
  const { credits, setCredits } = useContext(AuthContext);

  // const [state, updateState] = useState();
  const [search, setSearch] = useState();

  useInterval(async () => {
    setNotif(JSON.parse(localStorage.getItem("notif") === "0" ? false : true));
  }, 2000);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCases = async () => {
      try {
        const response = await axiosPrivate.get("/cases", {
          signal: controller.signal,
        });
        isMounted && setCases(response.data);
        setCasesFirst(response.data);
        localStorage.setItem("cases", JSON.stringify(response.data));

        isMounted && setNotif(false);
        isMounted && localStorage.setItem("notif", "0");
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    notif && getCases();

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notif]);

  useEffect(() => {
    if (search) {
      setCases(
        casesFirst.filter(
          (r) =>
            r.court.toUpperCase().includes(search.toUpperCase()) ||
            r.note.toUpperCase().includes(search.toUpperCase()) ||
            r.gak.toUpperCase().includes(search.toUpperCase())
        )
      );
    } else {
      setCases(casesFirst);
    }
  }, [search]);

  const [showModal, setShowModal] = useState(true);
  const handleClickAddCaseModal = (e) => {
    console.log(e?.key);
    if (e?.key === "Escape") {
      setShowModal(true);
    }
    if (e?.key === "Enter") {
      setShowModal(false);
      console.log(e.key);
    }
    if (!e?.key) {
      console.log(e);
      setShowModal(!showModal);
    }
  };
  const handleAddCase = (singleCase) => {
    setCases([singleCase, ...cases]);
  };
  const handleDeleteCase = (singleCase) => {
    setCases(cases.filter((e) => e.id.toString() !== singleCase));
  };
  const handleAddSelectedDiadikasia = (caseId, diadikasia) => {
    const findCase = cases.find((e) => e.id === caseId);
    findCase.userInput = [
      { category: "select_diadikasia", data: JSON.stringify(diadikasia) },
    ];
    setCases([...cases]);
  };

  return (
    <section className="astika">
      <section className="container_info">
        {/* <RotatingElements /> */}
        <Link to={`/exsolon`} className="addcase_modal analytika">
          Δικαστήρια εκτός solon.gov.gr
        </Link>

        <section className="container_search">
          {/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}

          {/* <div className="close_addcase"><p onClick={() => handleClickAddCaseModal()}>❌</p></div> */}
          {/* <span></span> */}
          <form className="form_search">
            {/* <label htmlFor="username">Email:</label> */}
            <div className="input-container ic1">
              <input
                className="input"
                placeholder=" "
                type="text"
                id="Αναζήτηση"
                autoComplete="on"
                onChange={(e) => setSearch(e.target.value)}
                value={search || ""}
                required
              />
              <div className="cut"></div>
              <label htmlFor="Αναζήτηση" className="placeholder">
                🔎 Αναζήτηση (πχ. Αθηνών)
              </label>
            </div>

            {/* <label htmlFor="password">Password:</label> */}
          </form>
        </section>

        {showModal ? (
          <AddCaseModal handleClickAddCaseModal={handleClickAddCaseModal} />
        ) : (
          <AddCase
            handleClickAddCaseModal={handleClickAddCaseModal}
            cases={cases}
            handleAddCase={handleAddCase}
            credits={credits}
            setCredits={setCredits}
          />
        )}
        <p className="courts-info">
          Τα δικαστήρια προς χάριν συντομίας εμφανίζονται, όπως εμφανίζονταν προ
          του Νέου Δικαστικού Χάρτη. Πχ. Το "Πρωτοδικείο Αθηνών (Πρώην
          Ειρηνοδικείο Αχαρνών)" εμφανίζεται ως "ΕΙΡ. ΑΧΑΡΝΩΝ". Παρακαλώ ελέγξτε
          ότι σας επιστρέφονται τα σωστά στοιχεία της υπόθεσής σας κατά την
          εισαγωγή.
        </p>
        <Cases
          prothesmies={prothesmies}
          cases={cases}
          handleDeleteCase={handleDeleteCase}
          handleAddSelectedDiadikasia={handleAddSelectedDiadikasia}
          credits={credits}
          setCredits={setCredits}
        />
      </section>
      {cases.length > 0 && (
        <section className="dashboard-astika">
          <DashboardTop cases={cases} columns={1} />
        </section>
      )}
    </section>
  );
};

export default Admin;
